body,
html,
:root {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  font-family: "Roboto Mono", sans-serif;
}

*:root,
:root::after,
:root::before {
  box-sizing: border-box;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

#root {
  background: url(/src/BG-blur.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
span,
div,
p {
  font-family: "Roboto Mono", monospace;
  margin: 0;
}

.main {
  width: 100%;
  display: grid;
  height: 100vh;
  overflow-y: visible;
  align-items: center;
}
@media screen and (max-width: 1496px) {
  .main {
    display: grid;
    grid-template-columns: 1fr repeat(12, 7.2rem) 1fr;
    grid-template-rows: auto;
    grid-gap: 2.4rem;
  }
}
@media screen and (max-width: 1180px) {
  .main {
    display: grid;
    grid-template-columns: minmax(0rem, 12rem) repeat(6, 128px) minmax(0rem, 12rem);
    grid-template-rows: auto;
    grid-gap: 2.4rem;
  }
}
@media screen and (max-width: 938px) {
  .main {
    display: grid;
    grid-template-columns: minmax(0rem, 2.4rem) repeat(6, 1fr) minmax(0rem, 2.4rem);
    grid-template-rows: auto;
    grid-gap: 2.4rem;
  }
}
@media screen and (max-width: 600px) {
  .main {
    display: grid;
    grid-template-columns: 1.6rem repeat(6, 1fr) 1.6rem;
    grid-template-rows: auto;
    grid-gap: 2.4rem;
  }
}
@media screen and (min-width: 1496px) {
  .main {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(18.6rem, 1fr) repeat(12, 72px) minmax(18.6rem, 1fr);
    grid-gap: 2.4rem;
  }
}
.bg-image {
  width: 100%;
  height: 100%;
  position: fixed;
  filter: blur(3rem);
  transform: scale(1.2);
  z-index: -1;
}

.section {
  height: fit-content;
  padding-bottom: 6.2rem;
}
@media screen and (max-width: 1496px) {
  .section {
    grid-column: 2/14;
  }
}
@media screen and (max-width: 1180px) {
  .section {
    grid-column: 2/8;
  }
}
@media screen and (max-width: 938px) {
  .section {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .section {
    grid-column: 2/8;
    width: 100%;
  }
}
@media screen and (min-width: 1496px) {
  .section {
    grid-column: 2/14;
  }
}
.section__container {
  width: 100%;
}
.section__title {
  font-size: 6.4rem;
  font-weight: 300;
  line-height: 7.2rem;
  letter-spacing: 0.2rem;
  margin-bottom: 1.6rem;
  font-family: "Roboto Mono", monospace;
  color: white;
}
@media screen and (max-width: 938px) {
  .section__title {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}
@media screen and (max-width: 600px) {
  .section__title {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}
.section__divider {
  margin-bottom: 1.6rem;
  width: 12rem;
  height: 3px;
  background-color: white;
}
.section__accent {
  color: white;
}
.section__subTitle {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 3.2rem;
  letter-spacing: 0.2rem;
  color: white;
}
@media screen and (max-width: 938px) {
  .section__subTitle {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
@media screen and (max-width: 600px) {
  .section__subTitle {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}
.section__subTitle span {
  display: block;
  font-size: 2rem;
  line-height: 2.4rem;
}
@media screen and (max-width: 600px) {
  .section__subTitle span {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.section__body {
  width: 100%;
  position: relative;
}
.section__body::after {
  position: absolute;
  content: "";
  width: 3.2rem;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 20px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}
.section__body::before {
  position: absolute;
  content: "";
  width: 3.2rem;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  border-radius: 20px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}
.section__body .cards {
  padding: 2.4rem 0rem;
  display: flex;
  gap: 2.4rem;
  overflow-y: visible;
  overflow-x: scroll;
  grid-template-rows: auto-fill;
}
@media screen and (max-width: 1180px) {
  .section__body .cards {
    display: flex;
    width: 100%;
  }
  .section__body .cards .card {
    min-width: 30rem;
  }
}
@media screen and (max-width: 600px) {
  .section__body .cards {
    display: flex;
    overflow-x: scroll;
    overflow-y: visible;
    width: 100%;
  }
  .section__body .cards .card {
    min-width: calc(100% - 3.2rem);
  }
}
.section__body.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
}
.section__body.skills::after, .section__body.skills::before {
  display: none;
}
.section__footer {
  display: flex;
  justify-content: center;
  gap: 2.4rem;
}
.section__footer button {
  background-color: transparent;
  border: none;
  color: white;
  border-bottom: 1px solid white;
}

.card {
  padding: 1.6rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.688);
  position: relative;
  min-width: 30rem;
  min-height: 38.5rem;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
}
.card.flip {
  transform: rotateY(180deg);
}
.card.flip .card__back {
  opacity: 1;
}
.card.flip .card__front {
  opacity: 0;
}
.card__front, .card__back {
  display: grid;
  grid-template-rows: auto;
  backface-visibility: hidden;
  padding: 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s ease;
}
.card__back {
  transform: rotateY(180deg);
  opacity: 0;
}
.card__back .card__body {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
}
.card.skills {
  width: 100%;
  min-width: unset;
  min-height: unset;
}
.card__heading {
  width: 100%;
  margin-bottom: 1.6rem;
}
.card__title {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: white;
}
@media screen and (max-width: 600px) {
  .card__title {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.card__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.6rem;
}
.card__wrapper.skills {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.card__year {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: white;
}
.card__type {
  font-family: "Roboto Mono", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: white;
  margin-bottom: 0.8rem;
}
@media screen and (max-width: 600px) {
  .card__type {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
.card__divider {
  height: 3px;
  background-color: white;
  width: 8rem;
}
.card__divider.skills {
  margin: 0 auto;
}
.card__body {
  display: flex;
  color: white;
  flex-wrap: wrap;
}
.card__body ul,
.card__body span,
.card__body li {
  font-family: "Roboto Flex", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
}
.card__body.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1.6rem;
}
.card__body .link {
  font-family: "Roboto Flex", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  width: 100%;
  height: min-content;
}
.card__body .link a {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  padding-bottom: 0.8rem;
  width: fit-content;
  margin: 0 auto;
  border-bottom: 1px solid white;
}
.card__footer {
  width: 100%;
  align-self: flex-end;
}
.card__footer button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  background-color: transparent;
  color: white;
  border: none;
  border-bottom: 1px solid white;
}
.card__footer .icon {
  margin-left: 0.8rem;
}
@media screen and (max-width: 938px) {
  .card .item {
    width: 5.6rem;
  }
}
.card .item__icon {
  margin-bottom: 0.8rem;
}
.card .item__icon img {
  display: block;
  margin: 0 auto;
}
.card .item__name, .card .item__ex {
  font-family: "Roboto Flex", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
}

.hide {
  visibility: hidden;
}